import axios from "axios";
import {keycloakStore} from "../store/LoginStates";

export class HTTPAxios {

    private axios;
    private readonly json: any;
    private readonly path: string;

    private readonly header = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, DELETE',
        'Authorization': ''
    }
    private readonly url = import.meta.env.VITE_BACKEND_HOST + "/";
    public isAuth = false;

    constructor(path: string, json?: any, isAuth?: boolean) {
        this.path = path;
        this.json = json;
        if (isAuth) this.isAuth = isAuth;

        this.axios = axios.create({
            baseURL: this.url,
            timeout: 10000,
            headers: this.header
        });
    }

    async get() {
        console.debug("[GET] " + this.url + this.path)
        if (this.isAuth) await this.updateToken();
        const urlPath = this.url + this.path;
        return await this.axios.get(urlPath);
    }

    async post(options: any) {
        console.debug("[POST] " + this.url + this.path)
        if (this.isAuth) await this.updateToken();
        const urlPath = this.url + this.path;
        return await this.axios.post(urlPath, this.json, options)
    }

    async delete() {
        console.debug("[DELETE] " + this.url + this.path)
        if (this.isAuth) await this.updateToken();
        const urlPath = this.url + this.path;
        return await this.axios.delete(urlPath, this.json)
    }

    async patch() {
        console.debug("[PATCH] " + this.url + this.path)
        if (this.isAuth) await this.updateToken();
        const urlPath = this.url + this.path;
        return await this.axios.patch(urlPath, this.json)
    }

    async updateToken() {
        if (keycloakStore.keycloak && keycloakStore.keycloak.authenticated && keycloakStore.keycloak.isTokenExpired(30)) {
            console.debug("Refresh token")
            await keycloakStore.keycloak.updateToken(60);
        }
        this.header.Authorization = 'Bearer ' + keycloakStore.keycloak.token;
        this.axios = axios.create({
            baseURL: this.url,
            timeout: 10000000,
            headers: this.header
        });
    }

}
