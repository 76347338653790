<template>
  <div class="configuration-wrapper">
    <div class="nav-bar">
      <div class="page-controller"
           v-for="route in $route.matched[0].children"
           @click="$router.push({name:route.name})"
           v-bind:class="{active: route.name === $route.name }">
        <img :src="(route.meta && route.meta.icon ? route.meta.icon : '')" alt="icon"/>
        <p>{{ route.name }}</p>
      </div>
    </div>
    <div class="nav-content">
      <router-view v-slot="{ Component }">
        <transition mode="out-in">
          <component :is="Component"/>
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.configuration-wrapper {
  display: flex;
  width: 100%;
  min-height: calc(100% - 55px);
  //height: 100%;

  .nav-bar {
    width: 200px;
    background-color: var(--secondary-background);
    height: inherit;

    .page-controller {
      display: flex;
      align-items: center;
      margin: 24px 12px 24px 0;
      padding: 8px 8px 8px 20%;
      border-radius: 0 4px 4px 0;
      cursor: pointer;

      &.active {
        background: var(--main);
      }

      p {
        font-size: 16px;
        font-family: Jost-SemiBold, serif;
      }

      img {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
  }

  .nav-content {
    padding: 28px;
    width: 100%;
    height: calc(100vh - 55px - 56px);
  }

}

.v-enter-active, .v-leave-active {
  transition: 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
