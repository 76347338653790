import {Alert, AlertType} from "./Alert";

export class MessageTemplates {

    public static getErrorAlert(): Alert {
        return {
            type: AlertType.ERROR,
            title: 'Erreur',
            content: 'Une erreur est survenue en tentant de contacté le serveur.'
        };
    }

    public static getForbiddenAccessAlert(): Alert {
        return {
            type: AlertType.ERROR,
            title: 'Accès refusé',
            content: 'Vous ne disposez pas des autorisations nécessaire pour acceder a cette page.'
        }
    }

    public static getBadRequest(): Alert {
        return {
            type: AlertType.ERROR,
            title: 'Mauvaise requête',
            content: 'La requête est corrompue ou incorrect.'
        }
    }

    public static getActionRefused(): Alert {
        return {
            type: AlertType.ERROR,
            title: 'Action refusé',
            content: "L'action que vous tentez d'effectuer à été bloquer par l'application"
        }
    }

    public static getUnsupportedMediaType(): Alert {
        return {
            type: AlertType.ERROR,
            title: 'Media non supporté',
            content: "Le media que vous tenté d'envoyer n'est pas supporter par l'application."
        }
    }

    public static getNotFound(): Alert {
        return {
            type: AlertType.ERROR,
            title: '404',
            content: "La requette n'as pas trouvé de destination"
        }
    }

    public static getGenericError(): Alert {
        return {
            type: AlertType.ERROR,
            title: 'Une erreur est survenue',
            content: "Une erreur inconnue est survenu"
        }
    }
}
