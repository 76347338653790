import {marked} from 'marked';
import Token = marked.Token;

export const craftingTable: | marked.TokenizerExtension
    | marked.RendererExtension
    | (marked.TokenizerExtension & marked.RendererExtension) = {
    name: 'craftingTable',
    level: 'block',
    start(src) {
        return src.match(/-=-/)?.index || undefined;
    },
    tokenizer(src, tokens) {
        // Regex can be improved but don't find better
        const rule = /^-=-\n([\s\S]*?)-=-\n/;
        const match = rule.exec(src);
        if (!match) {
            return undefined;
        }

        const token = {
            type: 'craftingTable',
            raw: match[0],
            tokens: [],
            childTokens: ['craftingCell', 'craftingResult'],
        };
        this.lexer.inlineTokens(token.raw, token.tokens);
        return token;
    },
    renderer(token) {
        if (token.tokens) {
            // Retrieve result slot token
            let tokenResult: Token | undefined;
            const tokenResults = token.tokens.filter((token) => {
                return token.raw.startsWith('-==')
            });
            if (tokenResults.length == 1) {
                tokenResult = tokenResults.at(0);
            }

            // Split cell by 3 rows
            const rows = [];
            for (let x = 0; x <= 2; x++) {
                rows.push(token.tokens.filter((y) => {
                    // @ts-ignore
                    return y.type == 'craftingCell'
                }).slice(3 * x, 3 * x + 3))
            }

            // Generate row and cell html
            let html = '<div class="crafter-container"><div class="crafting-table">'
            for (let row of rows) {
                html = html.concat('<div class="row">')
                row.forEach(cell => {
                    html = html.concat(this.parser.parseInline([cell]))
                })
                html = html.concat('</div>')
            }
            html = html.concat('</div>')
            if (tokenResult) {
                // Add arrow and result if exist
                html = html.concat('<svg width="90px" height="90px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7 12L17 12L13 16M15 10L13 8" stroke="#2c2c2c" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>')
                html = html.concat(this.parser.parseInline([tokenResult]))
            }
            html = html.concat('</div>')
            return html;
        } else return '';
    },
};

export const craftingCell: marked.TokenizerExtension | marked.RendererExtension = {
    name: 'craftingCell',
    level: 'inline',
    start(src) {
        return src.match(/==-/)?.index || -1;
    },
    tokenizer(src, tokens) {
        const rule = /^==- (.*)[\n ]*$/gm;
        const match = rule.exec(src);
        if (!match) {
            return undefined;
        }

        const token = {
            type: 'craftingCell',
            raw: match[0],
            image: match[1],
            tokens: [],
        };
        this.lexer.inline(token.image, token.tokens);
        return token;
    },
    renderer(token) {
        if (token.image)
            return `<div class="cell"><img src="${token.image}"></div>`;
        else return '<div class="cell"></div>'
    },
};

export const craftingResult: marked.TokenizerExtension | marked.RendererExtension = {
    name: 'craftingResult',
    level: 'inline',
    start(src) {
        return src.match(/-==/)?.index || -1;
    },
    tokenizer(src, tokens) {
        const rule = /^-== (.*)[\n ]*$/gm;
        const match = rule.exec(src);
        if (!match) {
            return undefined;
        }

        const token = {
            type: 'craftingResult',
            raw: match[0],
            image: match[1],
            tokens: [],
        };
        this.lexer.inline(token.image, token.tokens);
        return token;
    },
    renderer(token) {
        if (token.image)
            return `<div class="cell"><img src="${token.image}"></div>`;
        else return '<div class="cell"></div>'
    },
};

export const metaDataHunter: marked.TokenizerExtension | marked.RendererExtension = {
    name: 'metaDataHunter',
    level: 'inline',
    start(src) {
        return src.match(/%%/)?.index || -1;
    },
    tokenizer(src, tokens) {
        const rule = /%%([\s\S]*?)%%/g;
        const match = rule.exec(src);
        if (!match) {
            return undefined;
        }

        const token = {
            type: 'metaDataHunter',
            raw: match[0],
            iconLink: match[1],
            tokens: [],
        };
        this.lexer.inline(token.iconLink, token.tokens);
        return token;
    },
    renderer(token) {
        return ''
    },
};
