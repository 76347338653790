import valid from "../../../assets/icons/heart.png"
import warning from "../../../assets/icons/warning.png"
import error from "../../../assets/icons/error.png"

import {MessageTemplates} from "./MessageTemplates";

export class AlertType {
    static readonly VALID = new AlertType('VALID', valid)
    static readonly ERROR = new AlertType('ERROR', error)
    static readonly WARNING = new AlertType('WARNING', warning)
    static readonly INFO = new AlertType('INFO', valid)

    // private to disallow creating other instances of this type
    private constructor(private readonly key: string, public readonly value: any) {

    }

    toString() {
        return this.value;
    }
}

export interface Alert {
    type: AlertType,
    title: string,
    content: string,
    id?: number
}

export class AlertProvider {
    private alerts: Alert[] = [];

    constructor() {
    }

    public get getAlerts() {
        return this.alerts;
    }

    public sendAlert(alert: Alert) {
        alert.id = Math.floor(Math.random() * 1000)
        this.alerts.push(alert)

        setTimeout(() => {
            const index = this.alerts.indexOf(alert, 0);
            if (index > -1) {
                this.alerts.splice(index, 1);
            }
        }, 2500)
    }

    public handleError(status: number) {
        switch (status) {
            case 403:
                this.sendAlert(MessageTemplates.getForbiddenAccessAlert());
                break
            case 401:
                this.sendAlert(MessageTemplates.getForbiddenAccessAlert());
                break
            case 500:
                this.sendAlert(MessageTemplates.getErrorAlert());
                break
            case 400:
                this.sendAlert(MessageTemplates.getBadRequest());
                break
            case 415:
                this.sendAlert(MessageTemplates.getUnsupportedMediaType());
                break
            case 404:
                this.sendAlert(MessageTemplates.getNotFound());
                break
            case 690:
                this.sendAlert(MessageTemplates.getActionRefused());
                break
            default:
                this.sendAlert(MessageTemplates.getUnsupportedMediaType());
                break

        }
    }
}
