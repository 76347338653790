import {createApp, reactive} from 'vue'
import './style.scss'
import './font.scss'
import App from './App.vue'
import router from './router/index'
import {AlertProvider} from "./vue/global/alert/Alert";
import {keycloakStore} from "./store/LoginStates";
import {marked} from "marked";
import {craftingCell, craftingResult, craftingTable, metaDataHunter} from "./object/mdExtensions/MarkedExtension"

const app = createApp(App);

app.provide("alertProvider", reactive(new AlertProvider()))
app.use(router)

// Click outside custom event
app.directive('click-outside', {
    mounted(el, binding) {
        el.clickOutsideEvent = function (event: any) {
            if (!(el === event.target || el.contains(event.target))) {
                binding.value(event, el);
            }
        };
        window.requestAnimationFrame(() => {
            document.body.addEventListener('click', el.clickOutsideEvent)
        });
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    }
});

marked.use({
    extensions: [craftingTable, craftingCell, craftingResult]
})
app.mount('#app')



