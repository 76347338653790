import {createWebHistory, createRouter} from "vue-router";
import {keycloakStore} from "../store/LoginStates";
import Administration from "../components/Administration.vue";
import PageNotFound from "../components/PageNotFound.vue";
import folder from "../assets/icons/folder.svg"
import shopIcon from "../assets/icons/shop.svg"
import groups from "../assets/icons/groups.svg"


declare module 'vue-router' {
    interface RouteMeta {
        requiresAuth: boolean,
        icon?: string
    }
}

export const routes = [
    {
        path: "/",
        name: "Accueil",
        component: () => import('../components/Home.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/vote",
        name: "Vote",
        component: () => import('../components/Vote.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/shop",
        name: "Boutique",
        component: () => import('../components/Shop.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/wiki/:category?/:document?",
        name: "Wiki",
        component: () => import('../components/Wiki.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/leaderboard",
        name: "Classement",
        component: () => import('../components/LeaderBoard.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: "/admin",
        name: "Administration",
        component: Administration,
        redirect: '/admin/wiki-editor',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: "wiki-editor",
                name: "Wiki Editor",
                component: () => import('../components/configurators/WikiConfigurator.vue'),
                meta: {
                    requiresAuth: true,
                    icon: folder
                }
            },
            {
                path: "shop",
                name: "Shop",
                component: () => import('../components/configurators/ShopEditor.vue'),
                meta: {
                    requiresAuth: true,
                    icon: shopIcon
                }
            },
            {
                path: "users",
                name: "Utilisateur",
                component: () => import('../components/configurators/UserConfigurator.vue'),
                meta: {
                    requiresAuth: true,
                    icon: groups
                }
            },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        component: PageNotFound,
        meta: {
            requiresAuth: false
        }
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from) => {
    if (to.meta.requiresAuth) {
        if (!keycloakStore.isKeycloakInit) {
            keycloakStore.init(window.location.origin + to.path);
        }
        if (!keycloakStore.keycloak.authenticated) {
            //window.open(to.fullPath,'_self')
            //return false;
        }
    }
})

export default router;
