<template>
  <div class="footer-container">
    <div class="side-content left">
      <h2>HISTERIA</h2>
      <p>Histeria est un serveur PvP-Faction Minecraft Bedrock Edition "Moddé" Farm2Win ! Histeria se place
        régulièrement dans le top 6 mondial en terme de vote !</p>
    </div>
    <div class="side-content center">
      <img src="/src/assets/icons/logo_full.svg"/>
      <p>© {{ date }} - HISTERIA <span @click="router.push('/admin')">-</span> Tous
        droits réservés</p>
    </div>
    <div class="side-content shortcut-link">
      <h5>INFORMATIONS</h5>
      <a href="https://discord.gg/histeria">Discord</a>
      <a href="mailto:histeriamcpe@gmail.com">Mail: histeriamcpe@gmail.com</a>
      <p>Développé par <a href="https://zelytra.fr" target="_blank">Zelytra</a></p>
      <p>Désigné par <a href="https://zetro.fr" target="_blank">Zetro</a></p>
    </div>
  </div>

  <div class="footer-container-responsive">
    <div class="side-content">
      <img src="/src/assets/icons/logo_full.svg"/>
      <p>Histeria est un serveur PvP-Faction Minecraft Bedrock Edition "Moddé" Farm2Win ! Histeria se place
        régulièrement dans le top 6 mondial en terme de vote !</p>
    </div>
    <div class="side-content">
      <h5>INFORMATIONS</h5>
      <a href="https://discord.gg/histeria">Discord</a>
      <a href="mailto:histeriamcpe@gmail.com">Mail: histeriamcpe@gmail.com</a>
      <p>Développé par <a href="https://zelytra.fr" target="_blank">Zelytra</a></p>
      <p>Désigné par <a href="https://zetro.fr" target="_blank">Zetro</a></p>
    </div>
    <div class="side-content">
      <p>© {{ date }} - HISTERIA <span @click="router.push('/admin')">-</span> Tous
        droits réservés</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import router from "../../router";
import {ref} from "vue";

const date = ref<number>((new Date()).getFullYear())
</script>

<style scoped lang="scss">
.footer-container-responsive {
  display: none;
}

.footer-container {
  display: flex;
  background-color: #262626;
  height: 200px;
  justify-content: space-between;
  padding: 0 20px;
  position: sticky;
  top: 100vh;

  .side-content {
    padding: 20px;

    &.center {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        width: 420px;
      }

      p,span {
        font-family: Jost-SemiBold, serif;
        font-size: 16px;
        color: #9C9C9C;

        a {
          &:hover {
            color: var(--main);
          }
        }
      }
    }

    &.left {
      width: 15%;

      h2 {
        color: var(--main);
        font-family: Jost-SemiBold, serif;
        font-size: 26px;
      }

      p {
        font-family: Jost-Light, serif;
        font-size: 13px;
        color: #9C9C9C;
      }
    }

    &.shortcut-link {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h5 {
        font-family: Jost-SemiBold, serif;
        font-size: 18px;
        margin-bottom: 12px;
      }

      a, p {
        font-family: Jost-Light, serif;
        margin-bottom: 3px;
        font-size: 13px;
        color: #9C9C9C;
      }

      a:hover {
        color: var(--main);
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .footer-container {
    display: none;
  }

  .footer-container-responsive {
    display: flex;
    flex-direction: column;
    height: fit-content;
    justify-content: center;
    align-items: center;
    background-color: #262626;
    padding: 0 20px;
    position: sticky;
    top: 100vh;


    .side-content {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h2 {
        color: var(--main);
        font-family: Jost-SemiBold, serif;
        font-size: 26px;
      }

      p,span {
        font-family: Jost-Light, serif;
        font-size: 13px;
        color: #9C9C9C;
        max-width: 450px;
        text-align: center;
      }

      h5 {
        font-family: Jost-SemiBold, serif;
        font-size: 18px;
        margin-bottom: 12px;
      }

      a, p {
        font-family: Jost-Light, serif;
        margin-bottom: 3px;
        font-size: 13px;
        color: #9C9C9C;
      }

      a:hover {
        color: var(--main);
      }

      img {
        width: 260px;
      }


    }
  }
}
</style>
