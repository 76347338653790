import {reactive} from 'vue'
import Keycloak, {KeycloakConfig} from "keycloak-js";

let initOptions: KeycloakConfig = {
    url: import.meta.env.VITE_KEYCLOAK_HOST,
    realm: 'Histeria',
    clientId: 'public'
}

export const keycloakStore = reactive({
    keycloak: new Keycloak(initOptions),
    isKeycloakInit: false,

    init(redirectionUrl: string) {
        this.keycloak.init({
            onLoad: 'login-required',
            checkLoginIframe: false,
            redirectUri: redirectionUrl
            //silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        }).then((auth) => {
            this.isKeycloakInit = auth;
        }).catch((error) => {
        })

    },

    loginUser(redirectionUrl: string) {
        window.open(keycloakStore.keycloak.createLoginUrl({redirectUri: redirectionUrl}), '_self')
    }
})
