<template>
  <div class="login-user-info" v-if="isLogged">
    <img class="user-icon" src="/src/assets/icons/steve.png"/>
    <div class="user-info">
      <h3>{{ username }}</h3>
      <p>{{ role }}</p>
    </div>
    <img class="more-button" src="/src/assets/icons/more.svg" @click="toggleMore()"/>
    <transition>
      <div v-if="menuDisplay" class="menu" v-click-outside="()=>menuDisplay=false">
        <div class="menu-item" @click="keycloakStore.keycloak.logout()">
          <img src="/src/assets/icons/logout.svg"/>
          <p>Déconnexion</p>
        </div>
      </div>
    </transition>
  </div>
  <div class="sign-in" v-else>

  </div>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import Button from "../form/Button.vue";
import {keycloakStore} from "../../../store/LoginStates";

const menuDisplay = ref<Boolean>(false)
defineProps({
  isLogged: Boolean,
  username: String,
  role: String
})

function toggleMore() {
  menuDisplay.value = !menuDisplay.value;
}

</script>

<style scoped lang="scss">
.login-user-info {
  display: flex;
  align-items: center;
  margin-right: 12px;
  gap: 8px;
  position: relative;

  img {

    &.more-button {
      cursor: pointer;
    }

    &.user-icon {
      width: 36px;
      border-radius: 50%;
      border: solid var(--main) 3px;
      image-rendering: pixelated;
    }
  }

  .menu {
    position: absolute;
    top: 53px;
    right: -11px;
    background: var(--secondary-background);
    z-index: 99;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    overflow: hidden;

    .menu-item {
      padding: 24px;
      display: flex;
      gap: 6px;
      width: 140px;
      cursor: pointer;

      &:hover {
        background-color: var(--main);
      }

      img {
        width: 24px;
      }

    }
  }

}

.v-enter-active,
.v-leave-active {
  transition: all 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
