<template>
  <div class="header-wrapper">
    <div class="header-content">
      <img src="/src/assets/icons/H.png" alt="logo" style="cursor: pointer" @click="router.push('/')"/>
      <div class="nav-wrapper">
        <router-link class="router-link"
                     v-for="route in filteredRoutes"
                     :key="route.name"
                     :to="route.path.includes('wiki')?'/wiki/all':route.path">
          {{ route.name }}
        </router-link>
      </div>
    </div>
    <div class="header-content">

    </div>
    <div class="header-content">
      <a class="badge status" :class="{online:isOnline}"
         href="minecraft://?addExternalServer=Histeria%20V6|play.histeria.fr:19132">
        <img alt="status" src="/src/assets/icons/online.png" v-if="isOnline"/>
        <img alt="status" src="/src/assets/icons/offline.png" v-else/>
        <p v-if="isOnline">{{ playerCount }} joueur<span v-if="playerCount>1">s</span></p>
      </a>
      <a class="badge" href="https://discord.gg/histeria" target="_blank">
        <img src="/src/assets/icons/discord.svg"/>
        <p>Discord</p>
      </a>
      <UserSmartInfo :is-logged="keycloakStore.isKeycloakInit && keycloakStore.keycloak.authenticated"
                     :username="username"
                     :role="role"/>
    </div>
  </div>

  <div class="header-wrapper-responsive">
    <div class="header-content">
      <img src="/src/assets/icons/H.png" alt="logo" style="cursor: pointer" @click="router.push('/')"/>
    </div>
    <transition>
      <div class="header-content badge" v-if="!deployMenu">
        <a class="badge" href="https://discord.gg/histeria" target="_blank">
          <img src="/src/assets/icons/discord.svg"/>
          <p>Discord</p>
        </a>
        <a class="badge status" :class="{online:isOnline}"
           href="minecraft://?addExternalServer=Histeria%20V6|play.histeria.fr:19132">
          <img alt="status" src="/src/assets/icons/online.png" v-if="isOnline"/>
          <img alt="status" src="/src/assets/icons/offline.png" v-else/>
          <p v-if="isOnline">{{ playerCount }} joueur<span v-if="playerCount>1">s</span></p>
        </a>
      </div>
    </transition>
    <div class="header-content button" :class="{cross:!deployMenu}" @click="toggleMenu()">
      <span class="bar"/>
      <span class="bar"/>
      <span class="bar"/>
    </div>
    <transition name="menu">
      <div class="menu" v-if="!deployMenu">
        <router-link class="router-link"
                     v-for="route in filteredRoutes"
                     @click="toggleMenu()"
                     :key="route.name"
                     :to="route.path.includes('wiki')?'/wiki/all':route.path">
          {{ route.name }}
        </router-link>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import * as routes from '../../router'
import {inject, onMounted, ref} from "vue";
import axios from 'axios';
import {keycloakStore} from "../../store/LoginStates";
import {User} from "../../object/User";
import {KeycloakProfile} from "keycloak-js";
import UserSmartInfo from "./user/UserSmartInfo.vue";
import {HTTPAxios} from "../../object/HTTPAxios";
import {AlertProvider} from "./alert/Alert";
import {router} from "../../router";

const isOnline = ref(true);
const playerCount = ref(0);
const filteredRoutes = ref<any[]>([]);
const username = ref('')
const role = ref('')
const deployMenu = ref<boolean>(true)
const alertProvider = inject('alertProvider') as AlertProvider;

onMounted(() => {
  getPlayerCount("histeria.fr:19132").then(x => {
    isOnline.value = x.online;
    if (x.players) playerCount.value = x.players.online;
  })
  filterRouts()

  if (keycloakStore.isKeycloakInit && keycloakStore.keycloak.authenticated) {
    keycloakStore.keycloak.loadUserProfile().then(() => {
      new HTTPAxios('user/informations/', null, true).get().then((response) => {
        username.value = (response.data as User).username;
        role.value = (response.data as User).role
      }).catch((error) => {
        alertProvider.handleError(error.response.status);
      })
    })
  }
})

function filterRouts() {
  for (let route of routes.routes) {
    if (!route.meta.requiresAuth && route.name) {
      filteredRoutes.value.push(route)
      continue
    }
    if (keycloakStore.isKeycloakInit && keycloakStore.keycloak.authenticated) {
      filteredRoutes.value.push(route)
    }
  }
}

const getPlayerCount = async (ip: string) => {
  try {
    const response = await axios.get(`https://api.mcsrvstat.us/bedrock/2/${ip}`);
    return response.data;
  } catch (error) {
    console.warn("Failed to retrieve server data");
    return -1;
  }
};

function toggleMenu() {
  deployMenu.value = !deployMenu.value;
}
</script>

<style scoped lang="scss">
.header-wrapper-responsive {
  display: none;
}

.header-wrapper {
  z-index: 2;
  width: 100%;
  margin: auto;
  align-items: center;
  height: 55px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.25);
  background: var(--secondary-background);

  .header-content {
    display: flex;
    align-items: center;

    .badge {
      display: flex;
      padding: 7px;
      margin-right: 25px;
      align-items: center;
      border: 1px solid #4B9EFE;
      border-radius: 8px;
      height: fit-content;


      &:hover {
        background-color: var(--main);
      }

      &.status {
        background-color: transparent;
        border: solid #e53838 1px;

        &:hover {
          background-color: rgba(229, 56, 56, 0.15);
        }

        &.online {
          border: solid #00FF21 1px;

          &:hover {
            background-color: rgba(0, 255, 33, 0.15);
          }
        }
      }

      img, svg {
        margin-right: 8px;
        width: 24px;
      }

      img[alt="status"] {
        image-rendering: pixelated;
      }
    }

    .nav-wrapper {
      margin-left: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: Jost-Regular, serif;

      .router-link {
        margin-right: 28px;

        &:hover {
          color: var(--main);
        }
      }

      .router-link-active {
        color: var(--main);
        border-bottom: solid 2px var(--main);
      }
    }

    img {
      margin-left: 8px;
      width: 32px;
    }

  }
}

@media screen and (max-width: 1140px) {
  .header-wrapper {
    display: none;
  }

  .header-wrapper-responsive {
    z-index: 2;
    width: 100%;
    margin: auto;
    align-items: center;
    height: 55px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.25);
    background: var(--secondary-background);
    position: relative;

    .header-content {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.button {
        display: flex;
        flex-direction: column;
        width: 26px;
        height: 24px;
        cursor: pointer;
        margin-right: 8px;

        &.cross {
          :nth-child(1) {
            transform: rotate(45deg) translate(8px, 8px);
          }

          :nth-child(2) {
            opacity: 0;
          }

          :nth-child(3) {
            transform: rotate(-45deg) translate(8px, -8px);
          }

        }

        .bar {
          height: 2px;
          width: inherit;
          border-radius: 2px;
          background-color: var(--main);
        }
      }

      &.badge {
        width: 235px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media screen and (max-width: 300px) {
          width: auto;
        }
      }

      img {
        margin-left: 4px;
        width: 28px;
        user-select: none;

        &.action-menu {
          cursor: pointer;
          margin-right: 8px;
        }
      }

      .badge {
        display: flex;
        padding: 7px;
        align-items: center;
        height: fit-content;

        @media screen and (max-width: 300px) {
          &.status{
            display: none;
          }
        }

        img {
          margin-right: 6px;
        }

        img[alt="status"]{
          image-rendering: pixelated;
        }
      }
    }

    .menu {
      position: absolute;
      top: 55px;
      width: 100%;
      background: var(--secondary-background);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;

      .router-link {
        width: 100%;
        text-align: center;
        padding: 7px 0;
      }

      .router-link-active {
        color: var(--main);
        background-color: #1B1B1B;
      }
    }
  }

  .v-enter-active {
    transition: all 0.5s ease;
  }
  .v-enter-from,
  .v-leave-to {
    opacity: 0;
    transform: translateY(-8%);
  }

  .menu-enter-active {
    transition: all 0.5s ease-in-out;
  }
  .menu-enter-from,
  .menu-leave-to {
    opacity: 0;
    transform: translateY(-8%);
  }
}
</style>
